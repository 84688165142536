export default {
    init() {
      // JavaScript to be fired on the home page
    },
    finalize() {
      // JavaScript to be fired on the home page, after the init JS

      const archiveData = {
        id: '/news-events/news-archive/',
        text: 'News Archive',
      };
      const archiveOption = new Option(archiveData.text, archiveData.id);
      $('#wp-block-archives-3').append(archiveOption);//.trigger('change');
    },
  };
  