// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import search from './routes/search';
import blog from './routes/blog';
import 'van11y-accessible-accordion-aria/dist/van11y-accessible-accordion-aria.min.js';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Search page
  search,
  // News page
  blog,
});

// Load Events
jQuery(function () { routes.loadEvents(); });
