class FilterCards {
  constructor(element) {
    this.element = element;
    this.questions = Array.from(element.querySelectorAll('[data-filter-question]'));
    this.cards = Array.from(element.querySelectorAll('[data-filter-card]'));
    this.clearButton = element.querySelector('[data-filter-clear-button]');
    this.toggleButton = element.querySelector('[data-filter-questions-toggle]');
    this.questionsContainer = element.querySelector('[data-filter-questions-container]');
    this.questionsHeader = element.querySelector('[data-filter-questions-header]');

    this.questions.forEach(q => {
      Array.from(q.querySelectorAll('input')).forEach(option => {
        option.addEventListener('change', () => {
          this.updateCardStatus();
          this.clearButton.removeAttribute('disabled');
        });
      });
    });

    this.clearButton.addEventListener('click', () => {
      this.questions.forEach(q => {
        Array.from(q.querySelectorAll('input')).forEach(option => {
          option.checked = false;
        });
      });
      this.updateCardStatus();
      this.clearButton.setAttribute('disabled', 'disabled');
    });

    this.toggleButton.addEventListener('click', () => {
      if (this.questionsContainer.getAttribute('data-toggled')) {
        this.questionsContainer.removeAttribute('data-toggled');
        this.questionsHeader.removeAttribute('data-toggled');
      } else {
        this.questionsContainer.setAttribute('data-toggled', 'toggled');
        this.questionsHeader.setAttribute('data-toggled', 'toggled');
      }
    })
  }
  
  updateCardStatus() {
    this.cards.forEach(card => {
      card.removeAttribute('disabled');
      card.querySelector('[data-filter-card-screen-reader-text]').innerHTML = 'Resource matches criteria';
    })
    this.questions.forEach(q => {
      const checkedOption = q.querySelector('input:checked');
      if (checkedOption && checkedOption.value) {
        const key = q.getAttribute('data-filter-question');
        this.cards.forEach(c => {
          const cardValue = c.getAttribute(`data-${key}`);
          if (!cardValue.includes(checkedOption.value)) {
            c.setAttribute('disabled', 'disabled');
            c.querySelector('[data-filter-card-screen-reader-text]').innerHTML = 'Resource does not match criteria';
          }
        })
      }
    });
  }
}

export default {
  init() {
    // JavaScript to be fired on all pages

    /**
     * Hook up the filter cards.
     */
    document.addEventListener('DOMContentLoaded', () => {
      Array.from(document.querySelectorAll('[data-filter-cards]')).map(fc => new FilterCards(fc));
    });

    /**
     * Hide the nav better when the search bar is open.
     */

    const searchForm = document.getElementById('search-form');
    const searchInput = document.getElementById('s');
    let isFirstEvent = true;

    // AM: Browsers handle click/focusin differently and at different times. If issues arise, will need to redo search form
    searchForm.addEventListener('click', () => {
      if (!document.body.classList.contains('search-open')) {
        document.body.classList.add('search-open');
        searchInput.focus();
      }
    });

    searchForm.addEventListener('focusin', () => {
      if (!document.body.classList.contains('search-open')) {
        document.body.classList.add('search-open');
        if (isFirstEvent) {
          isFirstEvent = false;
          searchInput.focus();
        }
      }
    });

    /**
     * Show the nav again when focus is lost on the search bar 
     */
    searchForm.addEventListener('focusout', () => {
      setTimeout(() => {
        if (document.activeElement.id !== 's' && document.activeElement.id !== 'search-button') {
          document.body.classList.remove('search-open');
          isFirstEvent = true;
        }
      }, 300)
    });

    /**
     * The link that triggers the
     * back to top behaviour.
     * @type {HTMLElement}
     */
    const backToTopButton = document.querySelector('.back-to-top');

    /**
     * Show the back to top button
     * when scrolled partially down the page
     * @type {HTMLElement}
     */
    $('body').on('scroll', function () {
      $(this).scrollTop() > 500
        ? backToTopButton.classList.add('show')
        : backToTopButton.classList.remove('show');
    });

    /**
     * A list of all focusable elements
     * on the page.
     * @type {NodeList}
     */
    const focusableElements = document.querySelectorAll(
      'button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    /**
     * The options used in the native
     * scrollTo method.
     * @type {Object}
     */
    const scrollOptions = {
      top: 0,
      left: 0,
      behavior: 'smooth',
    };

    /**
     * Whether the device supports
     * smooth scroll, or not.
     * @type {Boolean}
     */
    const supportsNativeSmoothScroll =
      'scrollBehavior' in document.documentElement.style;

    /**
     * Handles moving the user back to the
     * top of the document.
     * @param event the event object passed in when
     * clicking on the button / link.
     */
    function moveToTop(event) {
      event.preventDefault();

      // Scroll to top.
      supportsNativeSmoothScroll
        ? window.scrollTo(scrollOptions)
        : window.scrollTo(scrollOptions.left, scrollOptions.top);

      // Focus the first focusable element.
      focusableElements[0].focus({
        preventScroll: true,
      });
    }

    backToTopButton.addEventListener('click', moveToTop);

    /**
     * Wrap iframes in a container that we can make responsive
     */
    $('p iframe').wrap('<div class="iframe-container"></div>');

    /**
     * Show fixed primary nav on scroll
     */
    let distance = '150'; // distance from the top of the page
    $(window).on('scroll', function () {
      if ($(window).scrollTop() >= distance) {
        $('body').addClass('show-fixed-nav');
      } else {
        $('body').removeClass('show-fixed-nav');
      }
    });

    /**
     * Remove the external link icon for links with images and blog post titles for cleanness
     */
    let blank_links = $('.main a[target="_blank"]');
    blank_links.each(function () {
      $(this).has('img').addClass('image-external-link');
      if ($(this).parent('.wp-block-post-title').length) {
        $(this).addClass('remove-external-link-icon');
      }
    });

    /**
     * Use Select2 for all select elements on the front end
     */
    if (jQuery.fn.select2) {
      $('select').select2();
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
