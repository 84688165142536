export default {
    init() {
        // JavaScript to be fired on the search page
        if (window.location.search.length > 0) {
            setTimeout(() => {
                document.body.classList.add('search-delay');
            }, 2000);
        }
    },
    finalize() {
        // JavaScript to be fired on the search page, after the init JS
    },
};
